import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';

import PageDefault from '@templates/page-template-default';

export const query = graphql`
  query($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      pageInfo {
        name
        slug {
          current
        }
      }
      id
      _rawBlocks(resolveReferences: { maxDepth: 20 })
      seo {
        ...SeoFragment
      }
      blocks {
        ... on SanityBannerSimple {
          ...BannerSimpleFragment
        }
        ... on SanityContactForm {
          ...ContactFormFragment
        }
        ... on SanityContentNarrow {
          ...ContentNarrowFragment
        }
        ... on SanityContent {
          ...ContentFragment
        }
        ... on SanityContentWithImage {
          ...ContentWithImageFragment
        }
        ... on SanityContentColumns {
          ...ContentColumnsFragment
        }
        ... on SanityHeroSubPage {
          ...HeroSubPageFragment
        }
        ... on SanityLocationsByGroup {
          ...LocationsByGroupFragment
        }
        ... on SanityLocationsMap {
          ...LocationsMapFragment
        }
      }
    }
  }
`;

const PageTemplate = props => {
  const pageData = props.data.page;
  _.omit(props, 'data'); // Remove 'data' since we don't want to send it to the component twice
  const pageProps = props;

  return <PageDefault pageProps={pageProps} pageData={pageData} />;
};

export default PageTemplate;
